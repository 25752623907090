.Home{
  .background-video {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    position: absolute;
  
  }
  .video-container{
    position: relative;
    width: 100%;
    max-height: 100%;
  }
}
