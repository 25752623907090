@import "./_variables.scss";

.bg- {
  &black {
    background-color: $color-black;
  }
  &blue {
    background-color: $color-blue;
  }
  &white {
    background-color: $color-white;
  }
  &orange {
    background-color: $color-orange;
  }
  &gray {
    background-color: $color-gray;
  }
}

a:focus {
  border-bottom: 1px solid $color-blue;
  outline: none;
}

.color- {
  &black {
    color: $color-black;
  }
  &blue {
    color: $color-blue;
  }
  &white {
    color: $color-white;
  }
  &orange {
    color: $color-orange;
  }
  &gray {
    color: $color-gray;
  }
}

html,
.fixed-top {
  min-width: 320px;
}

body {
  overflow: clip !important;
  font-family: "TT Norms";
  background-color: $color-black;
  letter-spacing: 0.1px;
}

a {
  color: unset;
  &:hover {
    color: unset;
    text-decoration: unset;
  }
  &:visited {
    color: unset;
    text-decoration: unset;
  }
}

@keyframes typing {
  from {
    overflow: hidden;
    white-space: nowrap;
    width: 0;
  }
  to {
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
}

@keyframes fading {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media only screen and (max-width:768px){
  body {
    overflow: auto !important;
  }
}