@import "../_variables.scss";
.QuemSomos{

  padding-bottom: 30px;
  
  .background-video {
    position: absolute;
    width: 43vw;
    height: 100%;
    object-fit: fill;
  }
  div {
    height: 100%;
  }
  h1 {
    margin-top: 150px;
    margin-bottom: 50px;
    display: block;
    font-weight: 800;
    font-size: 68px;
    letter-spacing: 2px;
    text-justify: inter-word;
    position: relative;
    color: $color-blue;
    animation: fade-in-only 3s linear 0s forwards;
  }
  @media (max-width: 767px) {
    h1 {
      font-size: 55px;
    }
  }
  #historia {
    letter-spacing: 0.3px;
    margin-bottom: 50px;
    margin-top: 0px;
    font-size: 19px;
    line-height: 40px;
    text-align: justify;
    height: 200px;
    display: block;
    padding-right: 10px;
    div {
      overflow: hidden;
      display: block;
      animation: fade-in 2s linear 0s forwards;
    }
  }
  .space {
    display: inline-block !important;
    margin-right: 20px !important;
  }
  .who-video-padding{
    padding-top: 8rem;
  }
  
  @keyframes letters-falling {
    0% {
      position: absolute;
      font-size: 250px;
      opacity: 0;
      bottom: 0;
    }
    99% {
      position: absolute;
      font-size: 68px;
      opacity: 1;
      bottom: 0;
    }
    100% {
      position: relative;
      opacity: 1;
    }
  }
  
  @keyframes fade-in {
    0% {
      height: 0px;
      opacity: 0;
    }
    99% {
      height: 415px;
      opacity: 1;
    }
    100% {
      overflow: show;
      opacity: 1;
      height: auto;
    }
  }
  
  @keyframes fade-in-only {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

@media only screen and (max-width:768px){
  #historia {
    overflow-y: scroll;
    scrollbar-color: #ededed #3c3c3c;
    scrollbar-width: thin;
  }
  .QuemSomos h1 {
    font-size: 40px;
  }
}