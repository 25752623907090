@import "../variables";

.Contato {
  display: flex;
  width: 100%;

  .background-video {
    height: 100%;
    max-height: 100%;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
    position: absolute;
  }

  .contato-container {
    position: relative;
    width: 100%;
    max-height: 100%;
  }

  .linhas{
    max-height: 450px;
  }

  .social-icon {
    position: absolute;
    width: 10%;
    transition: width 0.2s ease-in, height 0.2s ease-in, margin 0.2s ease-in;
    &:hover {
      width: 12%;
      margin-bottom: -1%;
      margin-left: -1%;
      cursor: pointer;
    }
  }

  .social-container {
    margin: 200px 50px 50px 50px;
  }

  #facebook {
    bottom: 80%;
    left: 94%;
  }
  #instagram {
    bottom: 16%;
    left: 50%;
  }
  #linkedin {
    bottom: -7%;
    left: 65%;
  }
  #pinterest {
    bottom: 89%;
    left: 43%;
  }
  #spotify {
    bottom: 60%;
    left: 67%;
  }
  #tiktok {
    bottom: 51%;
    left: -3%;
  }
  #twitter {
    bottom: 51%;
    left: -3%;
  }
  #vk {
    bottom: 76%;
    left: 93%;
  }
  #whatsapp {
    bottom: 3%;
    left: 20%;
  }
  #youtube {
    bottom: 54%;
    left: 33%;
  }

  .text {
    text-align: center;
    letter-spacing: 0.1px;
    h1 {
      font-size: 25px;
      color: $color-white;
      font-weight: 400;
    }
    h2 {
      animation: typing 0.5s linear 0s 1 forwards;
      font-size: 35px;
      color: $color-blue;
      font-weight: 400;
      b {
        color: $color-white;
        font-weight: 600;
      }
    }
  }

  @media (max-width: 550px) {
    .social-container {
      margin-top: 180px;
      margin-left: 20px;
      margin-right: 20px;
    }
    .text {
      h1 {
        font-size: 25px;
      }
      h2 {
        font-size: 30px;
      }
    }
  }
}
